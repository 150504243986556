<template>
    <div id="equipmentCameraSetting"  v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <my-header-title> 设备与摄像头关联管理 </my-header-title>
        <a-empty >
            敬请期待
        </a-empty>
    </div>    
</template>
<script>

import headerTitle from "../../../components/Title/headerTitle";
export default {
    data() {
        return {
            loading: false,
        };
    },
    components: {
        'my-header-title':headerTitle,
    },
    mounted(){

    },
    methods: {

    }
}
</script>
<style scoped>
    #equipmentCameraSetting{
        width: 100%;
        height: 100%;
        padding: 0 20px;
        font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
    }
</style>